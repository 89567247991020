<template>
  <v-container :fluid="true" class="synapsa-container px-0 pt-0">
    <v-row align="center" justify="center" class="mx-0">
      <v-col
        cols="12"
        class="px-2 py-2 synapsa-tabs second d-flex align-center"
      >
        <div class="mr-auto">
          <v-btn
            v-for="tab in tabs"
            v-permission="tab.permissions"
            :key="tab.id"
            :text="tab.id !== activeTab"
            :color="tab.id === activeTab ? 'primary' : ''"
            :outlined="tab.id === activeTab"
            small
            class="text-capitalize synapsa-tab"
            :class="tab.id === activeTab ? 'elevation-1' : ''"
            @click="to(tab)"
          >
            {{ $t(tab.name) }}
          </v-btn>
        </div>
        <span
          class="right mr-2 font-ubuntu-bold snapshot-date"
          v-if="monitorAuditorIntegrityBatchDate"
        >
          Snapshot:
          {{ monitorAuditorIntegrityBatchDate.toLocaleString() }}
        </span>
        <v-btn
          v-if="monitorAuditorIntegrityLoadBatch"
          class="right mr-2"
          small
          outlined
          @click="refreshBatch"
        >
          Show current state
        </v-btn>
        <v-btn class="right" small @click="showTimetravel">
          <v-icon color="primary">fas fa-calendar</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <router-view />
      </v-col>
    </v-row>
    <timetravel
      @loadBatch="setBatchToLoad($event)"
      @onClose="showTimetravelModal = false"
      :show="showTimetravelModal"
      :type="3"
    />
  </v-container>
</template>

<script>
import permission from "@/directive/permission/permission.js";
import Timetravel from "@/views/monitor/auditor/timetravel";

export default {
  directives: { permission },
  components: {
    Timetravel,
  },
  data() {
    return {
      activeTab: "integrityAlertActive",
      tabs: [
        {
          id: "integrityAlertActive",
          name: "monitor.auditor.ruleset.active",
          route: "active",
          permissions: ["view integrityactivealert"],
        },
        {
          id: "integrityAlertConfirmed",
          name: "monitor.auditor.ruleset.confirmed",
          route: "confirmed",
          permissions: ["view integrityconfirmedalert"],
        },
        {
          id: "integrityAlertHistory",
          name: "monitor.auditor.integrity.history",
          route: "history",
          permissions: ["view integrityconfirmedalert"],
        },
      ],
      showTimetravelModal: false,
    };
  },
  computed: {
    monitorAuditorIntegrityLoadBatch: {
      get() {
        return this.$store.state.synapsa.monitorAuditorIntegrityLoadBatch;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeMonitorBatch", {
          key: "monitorAuditorIntegrityLoadBatch",
          value: val,
        });
      },
    },
    monitorAuditorIntegrityBatchDate: {
      get() {
        return this.$store.state.synapsa.monitorAuditorIntegrityBatchDate;
      },
      set(val) {
        this.$store.dispatch("synapsa/changeMonitorBatch", {
          key: "monitorAuditorIntegrityBatchDate",
          value: val,
        });
      },
    },
  },
  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.activeTab = newValue.name;
      },
    },
  },
  methods: {
    to(tab) {
      this.$router.push(tab.route);
      this.activeTab = tab.id;
    },
    showTimetravel() {
      this.showTimetravelModal = true;
    },
    setBatchToLoad(calendarEvent) {
      this.monitorAuditorIntegrityLoadBatch = calendarEvent.eventId;
      this.monitorAuditorIntegrityBatchDate = calendarEvent.date;
      this.showTimetravelModal = false;
    },
    refreshBatch() {
      this.monitorAuditorIntegrityLoadBatch = undefined;
      this.monitorAuditorIntegrityBatchDate = undefined;
    },
  },
};
</script>
<style scoped lang="scss">
.right {
  float: right;
}
.snapshot-date {
  font-size: 0.75rem;
}
</style>
